<template>
  <div class="columns is-multiline is-mobile transactionDetail">
    <modal-add-givs-customer
      :addGivs="addGivs"
      v-if="modalAddGivs"
      :close="showModal"
    />
    <div class="column is-4 is-paddingless is-marginless has-text-left titleCard">
      Transacciones
    </div>
    <!-- <div class="column is-8 is-paddingless is-marginless has-text-right titleCard">
      <button class="button is-success is-small" @click="showModal">$</button>
    </div> -->
    <div class="column is-12 separator"></div>
    <div class="column is-12 top20" v-if="!loading">
      <div class="columns is-multiline is-mobile">
        <!-- <div class="column is-6 has-text-right">
          <p class="tagElement">Saldo $ (Recompensa):</p>
        </div>
        <div class="column is-6 has-text-left">
            <p class="valueElement">{{numberFormat(givs)}}</p>
        </div> -->
        <div class="column is-6 has-text-right">
          <p class="tagElement">Productos:</p>
        </div>
        <div class="column is-6 has-text-left">
            <p class="valueElement">{{productCount || 0}}</p>
        </div>
        <div class="column is-6 has-text-right">
          <p class="tagElement">Ventas:</p>
        </div>
        <div class="column is-6 has-text-left">
            <p class="valueElement">{{sold || 0}}</p>
        </div>
        <div class="column is-6 has-text-right">
          <p class="tagElement">Compras:
          </p>
        </div>
        <div class="column is-6 is-text-left">
            <p class="valueElement">{{purchases}}</p>
        </div>
        <div class="column is-6 has-text-right">
          <p class="tagElement">Referidos:
          </p>
        </div>
        <div class="column is-6 is-text-left">
            <p class="valueElement">{{totalReferreds}}</p>
        </div>
      </div>
    </div>
    <div class="column is-12 has-text-centered is-empty" v-else>
      Obteniendo información...
    </div>
  </div>
</template>

<script>
import mixin from '../../mixin/validateDate'
export default {
  name: 'CustomerTransactions',
  mixins: [mixin],
  props: {
    productCount: {
      type: Number
    },
    givs: {
      type: Number
    },
    sold: {
      type: Number
    },
    purchases: {
      type: Number
    },
    totalReferreds: {
      type: Number
    },
    modalAddGivs: {
      type: Boolean
    },
    showModal: {
      type: Function
    },
    addGivs: {
      type: Function
    },
    loading: {
      type: Boolean
    }
  },
  components: {
    ModalAddGivsCustomer: () => import('@/components/Customers/ModalAddGivs')
  }
}
</script>

<style scoped>
  .top20 {
    margin-top: 10px;
  }
  .tagElement {
    font-weight: bold;
    color: var(--secondary-color);
    font-size: 14px;
    padding-top: 5px;
  }
  .valueElement {
    font-size: 12px;
    font-weight: 300;
    padding-top: 5px;
  }
  .transactionDetail .is-6 {
    padding-bottom: 2px;
    padding-top: 2px;
  }
  .is-empty {
    font-size: 12px;
  }
</style>
